import AppAPI from 'api/app';
import { loadUserProject, loadUserProjectPowerCore, projectActions } from 'project/actions';
import { userActions } from 'actions/user';
import { getObsoleteFamilies } from 'actions/variables';
import { batch } from 'react-redux';
import i18n from 'i18next';
import { ThunkAction } from 'types/known-actions';
import { ApplicationState } from 'types/store';
import { VariablesActions } from 'store/variables/actions';
import { phLanguages, pcLanguages } from '../constants';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

export const SET_APP_VERSION = 'SET_APP_VERSION';

const processPartnerHubUrlParams = (
  dispatch: ThunkDispatch<ApplicationState, unknown, Action<string>>,
  state: ApplicationState,
  params: URLSearchParams,
  sldId: string,
  projectIsUL: boolean,
) => {
  const toolUrl = params.get('toolUrl') || undefined;
  const language = params.get('lang') || 'en';
  const country = params.get('country') || undefined;
  const user = params.get('user') || undefined;
  const name = params.get('name') || undefined;
  let initLang = 'en';

  if (language !== 'en' && phLanguages[language]) {
    initLang = phLanguages[language];
    i18n.changeLanguage(phLanguages[language]).catch((e) => console.log(e));
  }

  if (sldId && toolUrl) {
    dispatch(projectActions.setProjectInfo({ info: { singleLineDiagramId: sldId, ul: projectIsUL, name } }));
    dispatch(
      projectActions.setPartnerHubData({
        partnerHubData: {
          ...(state?.project?.partnerHub ?? {}),
          singleLineDiagramId: sldId,
          toolUrl: toolUrl ?? '',
          user: user ?? '',
        },
      })
    );
    dispatch(
      userActions.updateUserData({
        data: {
          language: initLang,
          userCountry: country ?? '',
          phLanguage: language,
          email: user ?? '',
        },
      })
    );

    dispatch(loadUserProject(sldId));
  }
}

const processPowerCoreUrlParams = (
  dispatch: ThunkDispatch<ApplicationState, unknown, Action<string>>,
  state: ApplicationState,
  params: URLSearchParams,
  projectId: string,
  projectIsUL: boolean
) => {
  const toolUrl = params.get('toolUrl');
  const language = params.get('language') ?? 'en';
  const lang  = pcLanguages[language];

  i18n.changeLanguage(lang);

  if (projectId && toolUrl) {
    dispatch(
      projectActions.setProjectInfo({
        info: {
          singleLineDiagramId: projectId,
          ul: projectIsUL,
        },
      })
    );
    dispatch(
      projectActions.setPowerCoreData({
        powerCoreData: {
          ...(state?.project.powerCore ?? {}),
          singleLineDiagramId: projectId ?? '',
          toolUrl: toolUrl,
        },
      })
    );
    dispatch(
      userActions.updateUserData({
        data: {
          language: lang,
          preferredLanguage: lang,
        },
      })
    );

    dispatch(loadUserProjectPowerCore(projectId));
  }
};

export const initApp = (): ThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  return AppAPI.getAppVersion().then((data) => {
    const params = new window.URLSearchParams(window.location.search);
    const projectId = params.get('projectId') || undefined;
    const sldId = params.get('sldId') || undefined;
    const projectIsUL = params.get('ul') === 'true';

    batch(() => {
      if (projectIsUL) {
        dispatch(VariablesActions.setVariables({ MainStandard: 'UL' }));
      }
      dispatch(projectActions.setAppVersion({ version: `Back:${data.version}|UI:${process.env.REACT_APP_VERSION}` }));
      if(projectId) {
        processPowerCoreUrlParams(dispatch, state, params, projectId, projectIsUL);
      } else if (sldId) {
        processPartnerHubUrlParams(dispatch, state, params, sldId, projectIsUL);
      } else {
        dispatch(getObsoleteFamilies(true));
      }
    })    
  })
};

export const getAppState = (): ThunkAction<ApplicationState> => (_dispatch, getState) => {
  return getState();
};
