import { dumpProject, loadUserProject, loadUserProjectPowerCore } from 'project/actions';

import UserAPI from 'api/user';

import { arrayBufferToBase64 } from 'actions/utils';
import PartnerHubAPI from 'api/partnerHubApi';

import { initialState as defaultVariables } from 'store/variables/store';
import { initialState as defaultProject } from 'store/project/store';
import { initialState as defaultStandards } from 'store/standards/store';
import { initialState as defaultLogo } from 'store/logo/store';
import { initialState as userSettingsInitialState } from 'store/userSettings/store';

import { ThunkAction } from 'types/known-actions';

import * as UserNamespace from 'types/user';
import * as UserSettingsNamespace from 'types/userSettings';

import _, { cloneDeep } from 'lodash';
import { createAction } from 'redux-actions';
import { USER, USER_SETTINGS } from 'types/constants.d';
import { ApplicationState } from 'types/store';
import { IGetProjectInformationResponse, ISaveUserSettings } from 'types/api/partnerHubApi';
import {
  IAttachedFiles,
  IGetInternalUserDataResponse,
  IGetProjectDataResponse,
  IGetUserProjects,
  ISaveUserProjectResponse,
  ISendEmailResponse,
} from 'types/api/user';
import { EPaperList } from 'types/project/store.d';
import { initialState as devicesInitialState } from 'store/devices/store';

import { standardsActions } from 'store/standards/actions';
import { setProjectLoading } from 'compute/actions';
import i18n from 'i18n';
import { showModal } from 'ui/modals/actions';
import { msalInstance } from 'index';
import PowerCoreAPI from 'api/powerCoreApi';
import { IGetProjectPCInformationResponse } from 'types/api/powerCoreApi';
import { availableLanguages } from '../constants';

export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SAVE_ABB_COUNTRIES = 'SAVE_ABB_COUNTRIES';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const SAVE_USER_INTERNAL_DATA = 'SAVE_USER_INTERNAL_DATA';
export const SAVE_CURRENT_USER_VIEW = 'SAVE_CURRENT_USER_VIEW';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_SHOW_DEMO_STATUS = 'UPDATE_SHOW_DEMO_STATUS';

export const userActions = {
  saveUserData: createAction<UserNamespace.Actions['saveUserData']['payload']>(USER.SAVE_USER_DATA),
  saveABBCountries: createAction<UserNamespace.Actions['saveABBCountries']['payload']>(USER.SAVE_ABB_COUNTRIES),
  saveCurrentUserView: createAction<UserNamespace.Actions['saveCurrentUserView']['payload']>(USER.SAVE_CURRENT_USER_VIEW),
  updateUserData: createAction<UserNamespace.Actions['updateUserData']['payload']>(USER.UPDATE_USER_DATA),
  selectCountry: createAction<UserNamespace.Actions['selectCountry']['payload']>(USER.SELECT_COUNTRY),
  saveUserInternalData: createAction<UserNamespace.Actions['saveUserInternalData']['payload']>(USER.SAVE_USER_INTERNAL_DATA),
  updateShowDemoStatus: createAction<UserNamespace.Actions['updateShowDemoStatus']['payload']>(USER.UPDATE_SHOW_DEMO_STATUS),
  updateUserMacros: createAction<UserNamespace.Actions['updateUserMacros']['payload']>(USER.UPDATE_USER_MACROS),
  setUserSettings: createAction<UserSettingsNamespace.Actions['setUserSettings']['payload']>(USER_SETTINGS.SET_USER_SETTING),
  pasteMacros: createAction<UserNamespace.Actions['pasteMacros']['payload']>(USER.PASTE_MACRO),
};

// export const saveUserData = (name: string, email: string, isAuthenticated: boolean) => ({
//   type: SAVE_USER_DATA,
//   name: name,
//   email: email,
//   isAuthenticated: isAuthenticated,
// });

// export const saveAbbCountries = (countryCode, countryName, defaultLanguageCode, defaultLanguageName, userCountry) => ({
//   type: SAVE_ABB_COUNTRIES,
//   countryCode: countryCode,
//   countryName: countryName,
//   defaultLanguageCode: defaultLanguageCode,
//   defaultLanguageName: defaultLanguageName,
//   userCountry: userCountry,
// });

export const selectUserCountry = (userCountry: string) => ({
  type: SELECT_COUNTRY,
  userCountry,
});

// export const saveUserInternalData = (userInternalId, name, registrationDate, lastVisitDate, userCountry, showDemo) => ({
//   type: SAVE_USER_INTERNAL_DATA,
//   userInternalId,
//   registrationDate,
//   lastVisitDate,
//   userCountry,
//   name,
//   showDemo,
// });

export const updateUserDefaultProjectProperties =
  (newState: Partial<ApplicationState> | null, clear = false): ThunkAction<Promise<string | void>> =>
  (dispatch, getState) => {
    const state = newState && !_.isEmpty(newState) ? cloneDeep(newState) : cloneDeep(getState());
    const variables = { ...state.variables } as Partial<ApplicationState['variables']>;
    // Remove not needed data in defaults;
    delete variables.PAPERSIZE;
    delete variables.ShortCircuitTimesObject;
    delete variables.Scenarios;
    delete variables.WEBDOC_CURRENT_DEVICE;
    const data = {
      DefaultProjectProperties: JSON.stringify(
        {
          variables: state.variables,
          labels: state.labels,
        },
        null,
        2
      ),
    };

    const updatedState = getState();
    if (updatedState.project.singleLineDiagramId && updatedState.project.partnerHub.projectId) {
      const requestData: ISaveUserSettings = {
        content: clear ? '' : data.DefaultProjectProperties,
        user: updatedState.project.partnerHub.user ?? '',
        documentName: 'settings',
        documentType: 'json',
        hookUrlUploadSettings: updatedState.project.partnerHub.hookUrlUploadSettings,
      };
      return PartnerHubAPI.saveUserSettings(requestData);
    }

    if (updatedState.project.powerCore.singleLineDiagramId) {
      const requestData: ISaveUserSettings = {
        content: clear ? '' : data.DefaultProjectProperties,
        user: '',
        documentName: 'settings',
        documentType: 'json',
        hookUrlUploadSettings: '',
      };
      return PowerCoreAPI.saveUserSettings(requestData);
    }
    return UserAPI.updateUserDefaultProjectProperties(state.user?.userInternalId.toString() ?? '', data);
  };

// commented out in navbar
// export const updateUserCountry = () => (dispatch, getState) => {
//   const state = getState();
//   const data = {
//     Country: state.user.userCountry,
//   };
//   return UserAPI.updateUserCountry(state.user.userInternalId, data);
// };

export const createUserProject =
  (projectName: string, projectData: string): ThunkAction<Promise<string | ISaveUserProjectResponse>> =>
  (dispatch, getState) => {
    const state = getState();
    if (state.project.powerCore.singleLineDiagramId) {
      const powerCoreProjectData = {
        projectId: state.project.powerCore.singleLineDiagramId,
        Id: state.project.powerCore.singleLineDiagramId,
        User: state.user.email,
        Documents: [
          {
            DocumentName: `${state.project.name}`,
            DocumentType: 'docweb',
            Content: projectData,
            isProjectSettings: true,
          },
        ],
      };
      return PowerCoreAPI.saveProjectData('project', powerCoreProjectData);
    }
    if (state.project.singleLineDiagramId && state.project.partnerHub.projectId) {
      const partnerHubProjectData = {
        ProjectId: state.project.partnerHub.projectId,
        Id: state.project.singleLineDiagramId,
        User: state.user.email,
        hookUrlUploadDocuments: state.project.partnerHub.hookUrlUploadDocuments,
        Documents: [
          {
            DocumentName: `${state.project.partnerHub.projectName} - ${state.project.partnerHub.singleLineDiagramName}`,
            DocumentType: 'docweb',
            Content: projectData,
            isProjectSettings: true,
          },
        ],
      };
      return PartnerHubAPI.saveProjectData('project', partnerHubProjectData);
    }
    return UserAPI.saveUserProject({
      projectName,
      projectData,
      created: state.project.created ? (state.project.created as Date) : new Date(),
      lastModified: state.project.updated ? (state.project.updated as Date) : new Date(),
      userId: state.user.userInternalId,
      singleLineDiagramId: state.project.singleLineDiagramId ?? '',
    });
  };

export const saveNewUserProject = (): ThunkAction<Promise<void>> => (dispatch, getState) => {
  const { variables, wizard, user, logo, docWebData } = getState();
  const name = wizard.projectName;
  // const CurrentStamp = layoutFromWizard(wizard.pageLayoutSelection, wizard.standards.powerSupply).frame;

  // TODO: check new page creation was commented in curves
  // const newPage = createPage(1, CurrentStamp);
  const projectData: Partial<ApplicationState> = {
    variables: {
      ..._.cloneDeep(defaultVariables),
      ...wizard.variables,
    },
    labels: docWebData.labels,
    project: {
      ..._.cloneDeep(defaultProject),
      name,
      CurrentStamp: EPaperList.A3,
      /**
       * A new default page is created, so increment the pageCounter
       * and set the default opened and selected
       */
      pageCounter: 2,
      selectedPage: 'block',
      // TODO check this 'newPage.id' (was like this in old code)
      pageOrder: ['block', 'newPage.id'],
      openedPages: ['block', 'newPage.id'],
      Customer: wizard.projectCustomer,
      description: wizard.projectDescription,
      userCountry: user.userCountry,
    },
    logo: {
      ..._.cloneDeep(defaultLogo),
      projectLogo: logo.projectLogo,
    },
    devices: { ..._.cloneDeep(devicesInitialState) },
    // lvCable: {
    //   ..._.cloneDeep(defaultLvCable),
    //   // ...wizard.lvCable,
    // },

    pages: docWebData.pages,
    // pages: {
    //   ..._.cloneDeep(defaultPages),
    //   [newPage.id]: newPage.page,
    // },
    connections: {},
    userSettings: { ..._.cloneDeep(userSettingsInitialState) },
  };

  if (variables.MainStandard === 'UL' && projectData.variables && projectData.project) {
    projectData.variables.MainStandard = 'UL';
    projectData.project.ul = true;
  }

  dispatch(standardsActions.setStandard({ ..._.cloneDeep(defaultStandards), ...wizard.standards }));
  const project = dispatch(dumpProject(projectData));

  return dispatch(createUserProject(name, project as string)).then((data) => {
    const projectState = getState().project;
    if (projectState.powerCore.singleLineDiagramId) {
      dispatch(loadUserProjectPowerCore(projectState.powerCore.singleLineDiagramId, true, true));
    } else if (typeof data === 'string' && projectState.partnerHub.singleLineDiagramId) {
      dispatch(loadUserProject(projectState.partnerHub.singleLineDiagramId, true, true));
    } else {
      dispatch(loadUserProject((data as ISaveUserProjectResponse).projectId.toString(), true, true));
    }
  });
};

export const updateUserProject = (): ThunkAction<Promise<string | void>> => async (dispatch, getState) => {
  const { project } = getState();
  if (project.partnerHub && project.partnerHub.singleLineDiagramId) {
    await dispatch(updateUserProjectPH());
    return;
  }

  if (project.powerCore && project.powerCore.singleLineDiagramId) {
    await dispatch(updateUserProjectPowerCore());
  }
};

const updateUserProjectPH = (): ThunkAction<Promise<string | void>> => (dispatch, getState) => {
  const state = getState();
  if (state.project.error) {
    console.warn('Cannot update the project, because you have project errors');
  }
  if (state.project.partnerHub.projectId) {
    const partnerHubProjectData = {
      ProjectId: state.project.partnerHub.projectId,
      Id: state.project.singleLineDiagramId ?? '',
      User: state.user.email,
      hookUrlUploadDocuments: state.project.partnerHub.hookUrlUploadDocuments,
      Documents: [
        {
          DocumentName: `${state.project.name}`,
          DocumentType: 'docweb',
          Content: dispatch(dumpProject()),
          isProjectSettings: true,
        },
      ],
    };
    return PartnerHubAPI.saveProjectData('project', partnerHubProjectData);
  }
  return UserAPI.updateUserProject(state.project.id ?? '', {
    projectName: state.project.name,
    projectData: dispatch(dumpProject()),
    lastModified: new Date(),
  });
};

const updateUserProjectPowerCore = (): ThunkAction<Promise<string | void>> => (dispatch, getState) => {
  const state = getState();
  if (state.project.error) {
    console.warn('Cannot update the project, because you have project errors');
  }
  if (!state.project.powerCore || !state.project.powerCore.singleLineDiagramId) {
    return UserAPI.updateUserProject(state.project.id as string, {
      projectName: state?.project?.powerCore?.projectName ?? '',
      projectData: dispatch(dumpProject()),
      lastModified: new Date(),
    });
  }
  const powerCoreProjectData = {
    projectId: state.project.powerCore.singleLineDiagramId,
    Documents: [
      {
        DocumentName: `${state?.project?.powerCore?.projectName}`,
        DocumentType: 'docweb',
        Content: dispatch(dumpProject()),
        isProjectSettings: true,
      },
    ],
  };
  return PowerCoreAPI.saveProjectData('project', powerCoreProjectData);
};

export const getUserProjects = (): ThunkAction<Promise<Array<IGetUserProjects>>> => (dispatch, getState) => {
  const state = getState();
  return UserAPI.getUserProjects(state.user.userInternalId);
};

export const getRecentUserProjects = (): ThunkAction<Promise<Array<IGetUserProjects>>> => (dispatch, getState) => {
  const state = getState();
  return UserAPI.getRecentProjects(state.user.userInternalId);
};

export const deleteUserProject = (id: number): Promise<void> => {
  return UserAPI.deleteProject(id);
};

export const getUserProjectData =
  (
    id: string,
    sessionId: string,
    sessionLastModified: Date,
    forceUnlock: boolean
  ): ThunkAction<Promise<IGetProjectDataResponse | IGetProjectInformationResponse>> =>
  (dispatch, getState) => {
    const state = getState();
    const sldId = state.project.singleLineDiagramId || state.project.partnerHub.singleLineDiagramId;

    if (sldId && sldId === id) {
      const requestData = {
        id: sldId,
        user: state.project.partnerHub.user,
        toolUrl: state.project.partnerHub.toolUrl,
      };
      return PartnerHubAPI.getProjectInfo(requestData);
    }
    return UserAPI.getProjectData(id, sessionId, sessionLastModified, `${forceUnlock ? 'true' : 'false'}`);
  };
//@ts-ignore
export const getPCUserProjectData = (): ThunkAction<Promise<IGetProjectPCInformationResponse>> => (dispatch, getState) => {
  const state = getState();
  const projectIdPC = state.project.powerCore.singleLineDiagramId;
  const requestData = {
    projectId: projectIdPC,
    toolUrl: state.project.powerCore.toolUrl,
  };

  return PowerCoreAPI.getProjectInfo(requestData).then((project) => {
    if (project.downloadUrl === '') {
      return project;
    } else {
      return PowerCoreAPI.downloadProject(project.downloadUrl).then((blob) => ({
        ...project,
        projectData: blob,
      }));
    }
  });
};

// export const updateUserProjectSession = (id, sessionId, sessionLastModified, forceUnlock) => (dispatch, getState) => {
//   return UserAPI.getProjectData(id, sessionId, sessionLastModified, forceUnlock);
// };
export const updateUserProjectSession = (): ThunkAction<void> => (dispatch, getState) => {
  const projectState = getState().project;
  const lastSession = new Date();
  UserAPI.getProjectData(projectState.id ?? '', projectState.sessionId, lastSession, 'false')
    .then((sessionIsValid) => {
      if (!sessionIsValid) {
        dispatch(
          showModal('ProjectLock', {
            projectId: projectState.id,
            projectName: getState().project.projectName,
            partnerHub: !!getState().project.singleLineDiagramId,
            sessionExpired: true,
          })
        );
        dispatch(setProjectLoading(false));
      }
      setTimeout(() => dispatch(updateUserProjectSession()), 5000);
    })
    .catch((err) => {
      dispatch(setProjectLoading(false));
      console.log(err);
    });
};

export const changeLanguage = (language: string): string => {
  const isProduction = window.document.location.hostname === 'tripcurves.econfigure.abb.com';
  const isPartnerHub = new window.URLSearchParams(window.location.search).get('sldId') !== null;
  if (isProduction && isPartnerHub && !availableLanguages.includes(language)) {
    language = 'en';
  } else if (!language) {
    language = 'en';
  }
  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }
  return language;
};

export const initUser = (): ThunkAction<Promise<void | IGetInternalUserDataResponse>> => (dispatch, getState) => {
  // dispatch(setProjectLoading(true));
  // dispatch(updateLoadStatus(i18n.t('DOCWEB_LOADING_GETUSERPROJECT'), 0));
  const userData = msalInstance.getActiveAccount();
  // console.log(userData);
  if (userData && userData.name && userData.username) {
    dispatch(userActions.saveUserData({ name: userData.name, email: userData.username, isAuthenticated: true }));
  }
  const { project } = getState();
  const user = _.cloneDeep(getState().user);
  if (!user.email) {
    if (project.partnerHub.user) {
      user.email = project.partnerHub.user;
    } else {
      const params = new window.URLSearchParams(window.location.search);
      const userFromParams = params.get('user');
      if (userFromParams) {
        user.email = userFromParams;
      }
    }
  }
  return UserAPI.getInternalUserData({ email: user.email }).then((internalUser) => {
    if (internalUser && internalUser.userId) {
      dispatch(
        userActions.saveUserInternalData({
          userInternalId: internalUser.userId,
          name: internalUser.name,
          registrationDate: internalUser.registrationDate ? internalUser.registrationDate.toString() : new Date().toISOString(),
          lastVisitDate: internalUser.lastVisitDate ? internalUser.lastVisitDate.toString() : new Date().toISOString(),
          language: internalUser.preferredLanguage || user.language,
          showDemo: internalUser.showDemo,
        })
      );
      if (internalUser.preferredLanguage && project.partnerHub.singleLineDiagramId) {
        const language = changeLanguage(internalUser.preferredLanguage);
        dispatch(
          userActions.updateUserData({
            data: { language: language, preferredLanguage: language },
          })
        );
      }
      // dispatch(setProjectLoading(false));
    }
    // UserAPI.addUser({
    //   Email: user.email,
    //   LastVisitDate: user.lastVisitDate || new Date().toISOString(),
    //   Name: user.name,
    //   RegistrationDate: user.registrationDate || new Date().toISOString(),
    // })
    //   .then((response) => {
    //     dispatch(setProjectLoading(false));
    //     if (!response) {
    //       return;
    //     }
    //     const newUser = response.value;
    //     dispatch(
    //       userActions.saveUserInternalData({
    //         userInternalId: parseFloat(newUser.userId),
    //         name: '',
    //         registrationDate: newUser.registrationDate,
    //         lastVisitDate: newUser.lastVisitDate,
    //         showDemo: newUser.showDemo,
    //       })
    //     );
    //     // eslint-disable-next-line consistent-return
    //     return newUser;
    //   })
    //   .catch((err) => console.log(err));
  });
};

export const sendSupportMail =
  (
    subject: string,
    mailBody: string,
    attachProject: boolean,
    attachedFiles?: Array<File>
  ): ThunkAction<Promise<ISendEmailResponse> | undefined> =>
  // eslint-disable-next-line consistent-return
  (dispatch, getState) => {
    const state = getState();
    const promises = new Array<Promise<void>>();
    const files = new Array<IAttachedFiles>();
    const helpInformation = {
      From: state.user.email,
      Project: attachProject ? (dispatch(dumpProject()) as string) : null,
      AttachedFiles: files,
      ProjectName: `${state.project.name}`,
      Subject: subject,
      MailBody: mailBody,
    };
    if (attachedFiles) {
      attachedFiles.forEach((atFile) => {
        promises.push(
          atFile.arrayBuffer().then((arrX) => {
            helpInformation.AttachedFiles.push({
              content: arrayBufferToBase64(arrX),
              type: atFile.type || atFile.name.split('.').pop() || '',
              name: atFile.name,
            });
          })
        );
      });
      Promise.all(promises)
        .then(() => {
          return UserAPI.sendHelpMail(helpInformation);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      return UserAPI.sendHelpMail(helpInformation);
    }
  };
